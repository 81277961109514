import React from "react";
import LoginForm from "../components/forms/login";
import Layout from "../components/layout";
import SEO from "../components/seo";

const flapmaxlogo = require("../images/flapmaxlogo.jpg");
const mainhero = require("../images/mainhero.jpeg");

const FastIndex = () => {
  return (
    <Layout>
      <SEO title="Login" description="" lang="en" meta={[]} />
      <section
        style={{ minHeight: "100vh", backgroundImage: `url(${mainhero})` }}
        className="pt-3"
      >
        <div>
          <img src={flapmaxlogo} alt="Flapmax" height={45} />
        </div>
        <section className="text-light pb-xxl-2">
          <h1 className="mt-5">FAST Portal</h1>
          <h2>Digital Matching Engine</h2>
        </section>
        <LoginForm />
      </section>
    </Layout>
  );
};

export default FastIndex;
